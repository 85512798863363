define([
  "underscore",
  "jquery",
  "common",
  "backboneRadix",
],function(_, $, common, Backbone)
{
  var o = {
    $el: null,
    detached: null,
    initialized: false,
    options: {},
    state: null,
    new: function(el,opt)
    {
      var o = Object.create(this);
      var promise = o.create(el,opt);

      if (typeof promise === 'object' && typeof promise.then === 'function')
      {
        return promise.then(() => o );
      }
      else
      {
        return common.okPromise(o);
      }
    },
    newDetached: function(opt)
    {
      var o = Object.create(this);
      var promise = o.create($("<div>"), opt);

      if (typeof promise === 'object' && typeof promise.then === 'function')
      {
        return promise.then(() => o );
      }
      else
      {
        return common.okPromise(o);
      }
    },
    create: function(el,opt)
    {
      var me = this;
      this.serial =  _.uniqueId('module-');
      this.$el = $('<div>', { id: this.serial, html: '<div style="display:flex;justify-content:center;align-items:center;" title="Модуль инициализируется">' });
      this.initialized = false;
      this.options = opt || {};
      this.state = null;
      this.detached = null;

      _.each(["parent","collection"], function(o)
      {
        if (me.options[o]) me[o] = me.options[o];
      });
      this.attachTo(el);
      // console.log("create >>", this.name);

    },
    attachTo: function(el)
    {
	if ( !el ) throw new Error("El to attach module not set!");
	this.$attachedTo = $(el);
	this.attach();
    },
    attach: function()
    { 
	if ( !this.$attachedTo ) throw new Error("El to attach module not found!");
	this.$attachedTo.empty().append(this.$el);
	this.detached = false;
	this.trigger("visual:attached");
    },
    detach: function()
    {
	this.$el.detach();
        this.detached = true;
	this.trigger("visual:detached");
    },

    extend: function(newProperties)
    {
      for (var propertyName in newProperties) 
      {
        this[propertyName] = newProperties[propertyName];
      }
      
      return this;
    },
    
    renderFromTemplate: function(template, data)
    {
      if (!this._cachedTemplate) this._cachedTemplate = _.template(template);
      this.$el.empty().append(this._cachedTemplate(data));
    },
    
    getState: function()
    {
      return this.state;
    },
    
    callUp: function()
    {
      var args = _.toArray(arguments);
      var proc =  args.shift();
          
      var curr = this.parent;
      while(1)
      {
        if (!curr) throw new Error("callUp: no '"+proc+"' method in visual chain");
        if (curr[proc] && _.isFunction(curr[proc])) return curr[proc].apply(this,args);
        curr = curr.parent;
      }
    },
    
    callSibling: function()
    {
      if (this.parent) this.parent.callChild.apply(this.parent,arguments);
    },
    
    $: function(selector)
    {
      return this.$el.find(selector);
    },
    
  };

  _.extend(o, Backbone.Events);
  return o;
});
