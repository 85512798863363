/* loader.js */

window.CKEDITOR_BASEPATH = `/node_modules/ckeditor/`

// Load your custom config.js file for CKEditor.
require(`!file-loader?context=${__dirname}&outputPath=node_modules/ckeditor/&name=[path][name].[ext]!./config.js`)

// Load your custom contents.css file in case you use iframe editor.
require(`!file-loader?context=${__dirname}&outputPath=node_modules/ckeditor/&name=[path][name].[ext]!./contents.css`)

// Load your custom styles.js file for CKEditor.
require(`!file-loader?context=${__dirname}&outputPath=node_modules/ckeditor/&name=[path][name].[ext]!./styles.js`)

// Load files from plugins.
require.context(
  '!file-loader?name=[path][name].[ext]!ckeditor/plugins/',
  true,
   /^\.\/((a11yhelp|ajax|autocomplete|basicstyles|blockquote|button|clipboard|contextmenu|dialog|dialogui|elementspath|emoji|enterkey|entities|fakeobjects|filebrowser|filetools|floatingspace|floatpanel|format|horizontalrule|htmlwriter|image|indent|indentlist|lineutils|link|list|listblock|magicline|maximize|menu|menubutton|notification|notificationaggregator|panel|panelbutton|pastefromword|pastetext|popup|removeformat|resize|richcombo|showborders|sourcearea|specialchar|stylescombo|tab|table|tableselection|tabletools|textmatch|textwatcher|toolbar|undo|uploadimage|uploadwidget|widget|widgetselection|wsc|wysiwygarea|xml)(\/(?!lang\/)[^/]+)*)?[^/]*$/
)

// Load lang files from plugins.
// Limit to active plugins with
// Object.keys(CKEDITOR.plugins.registered).sort().toString().replace(/,/g, '|')
require.context(
  '!file-loader?name=[path][name].[ext]!ckeditor/plugins/',
    true,
      /^\.\/(specialchar)\/(.*\/)*lang\/(ru)\.js$/
)

require.context(
 '!file-loader?name=[path][name].[ext]!ckeditor/plugins/',
    true,
    /^\.\/(emoji)\/(.*\/)*lang\/(en)\.js$/
)
            
// Load CKEditor lang files.
require.context(
        '!file-loader?name=[path][name].[ext]!ckeditor/lang',
        true,
        /(ru)\.js/
)
            
// Load skin.
require.context(
              '!file-loader?name=[path][name].[ext]!ckeditor/skins/moono-lisa',
                true,
                  /.*/
)
