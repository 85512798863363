define(["jquery","underscore","darsan"], function($, _, darsan)
{
  return {
    _list: [],
    
    _loadPromise: function()
    {
      var me = this;
      
      if (me._list.length) { return $.Deferred().resolve(me._list).promise() }
      
      return $.when( darsan.get("", "device", "/node"),
                     darsan.get("", "device", "/device") )
        .then(function(nodes,devices_data)
        {
          var devices = _.sortBy(devices_data, device => device.device_comment );

          devices = _.groupBy(devices, device => device.node );

          var groups = [];

          nodes.forEach(function (node, index) 
          {
            var group = {};
            group.text = node.name;
            group.children = [];

            devices[node.entity].forEach(function (device, i) 
            {
              group.children.push({
                id: device.device_id,
                text: device.device_comment
              });
            });

            groups.push(group);
          });
           
           me._list = groups;
           return me._list;
         });    
    },
    
    create: function(cont, name, options)
    {
      var me = this;
      var options = options || {};
    
      var input = $("<input type='number'/>",{name: name});
      $(cont).append(input);
      
      var pollerButton = $("<button />", {
        style: "vertical-align: middle; padding: 0;",
        title: "Открыть устройство в poller",

        click: function () {
          var dev = $(input).val();
          if (dev) window.open("http://poller.makeevka.com/device/details/"+dev);
        },
      });
      
      pollerButton.append("<img src='_lib/device-input/device.png'>");
      pollerButton.insertAfter(input);

      me._loadPromise()
      .fail(darsan.err)
      .done(function(groups)
      { 
        options.placeholder = "Выберите устройство";
        options.escapeMarkup = function (m) { return m };
        options.data = groups;
        $(input).select2(options);
      });
      
      return input;
    },

  };
});
