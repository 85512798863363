<template>
  <input class="form-control" :name=name :value=value />
</template>

<script>
import * as select_adv from '_lib/select_adv/select_adv'
import _ from "underscore"
import $ from "jquery"

export default {
  name: "SelectAdv",

  props: {
    name: {
      type: String,
    },
    value: {
       type: String,
    },
    init: Function,
    search: Function,
    advSearch: Function,
    limit: {
      type: Number,
      default: 15,
    },
    leftAddonTemplate: {
      type: String
    }
  },
  
  mounted()
  {
    const me = this

    select_adv($(this.$el), {
      limit: me.limit,
/*
      oninit: function(opt, func)
      {
        func(me.init(opt))
      },
*/
      onsearch: function(what, func)
      {
        me.search(what).then(data => func(data))
      },
/*
       onadvsearch: function(opt, func)
       {
         me.advSearch(opt).then(data => func(data))
       },
*/
       onselect: function(opt, func)
       {
          func(opt)
          me.$emit("input", opt.id)
        },

        leftAddonTemplate: me.leftAddonTemplate
      })

    },
}
</script>
