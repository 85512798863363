"use strict";
define([
    'jquery',
    'underscore',
    'navigation',
    'backboneRadix',
    'vue/dist/vue.esm.js',
    'text-loader!common/dialog-bs/layout.tpl',
    'store'
], function($, _, navigation, Backbone, Vue, html, store)
{
    $("body").append(html);

    return {

        show: function(title,html,footer)
        {
            var dialog = $("#module-dialog");
            dialog.find(".modal-title").empty().append(title);
            dialog.find(".modal-body").empty().append(html);
            dialog.find(".modal-footer").empty().append(footer);
            dialog.modal("show");
        },
        
        async showComponent(path, title, state={}, options={})
        {
            var dialog = $("#module-dialog");
            dialog.find(".modal-body").append("<div></div>")
            const cont = dialog.find(".modal-body div")
            
            var proxy = _.extend({
              close: function() { dialog.modal("hide") }
            }, Backbone.Events);

            var modCtor = await navigation.promiseLoadModule(path)
            modCtor.store = store.default

            const mod = new Vue.default(modCtor)

            mod.$mount(cont[0]);
            mod.setState(state);
                
            if (options.zIndex)
            {
              $(dialog).css('z-index', options.zIndex)
            }

            dialog.find(".modal-title").empty().append(title);
                
            dialog.off().on("hide.bs.modal", function()
            {
              proxy.trigger("close");
              dialog.find(".modal-body div").remove()
            });

            dialog.modal("show");
            
            return proxy;
        },

        showModule: function(mod,options,state)
        {
            if (_.isUndefined(state))
            {
              state = options;
              options = undefined;
            }
           
            state = state || {};
            options = options || {}; 

            var dialog = $("#module-dialog");
            var body = dialog.find(".modal-body");
            
            var proxy = _.extend({
              close: function() { dialog.modal("hide") }
            }, Backbone.Events);

            navigation.promiseCreateModule(mod,body,options).then(function()
            {
                if (!mod.detached)
                {
                    mod.detach();
                }
                mod.attachTo(body);
                mod.setState(state);
                
                if (options.zIndex)
                {
                  $(dialog).css('z-index', options.zIndex)
                }

                dialog.find(".modal-title").empty().append(mod.title);
                
                dialog.off().on("hide.bs.modal", function()
                {
                  mod.detach();
                  proxy.trigger("close");
                });

                dialog.modal("show");
            });
            
            return proxy;
        },
        
        close: function()
        {
          $("#module-dialog").modal("hide");
        },

    };
});
        