define([
  'common',
  'jquery',
  'underscore',
  'common/visual/oneof',
  'navigation',
  'text-loader!common/visual/tab.tpl',
], function(common,$, _, OneOf, nav, template)
{
  return Object.create(OneOf).extend({

    template: template,
    style: {},

    create: function(el, opt)
    {
      var me = this;
      OneOf.create.apply(me, arguments);
      _.bindAll(me, "tabShown", "tabHidden");

      this.layout.forEach((el,i) =>
      {
        var id = me.name + "-" + i;
        var a = me.$("a[href=\"#"+id+"\"]");
        a.on("show.bs.tab", ev => me.tabShown(i) );
        a.on("hide.bs.tab", ev => me.tabHidden(i) );
      });
    },
    
    ///////////////////////////////////////////////////////
    
    setState: function(state)
    {
      var me = this;
      if (_.isEqual(me.state,state)) return;
      me.state = state;
      
      //console.log("rendering tab",me.name,me.state);
      var needs = me.state[me.name] || "";
      var i = me.findModuleIndex(needs) || 0;
      me.state[me.name] = needs;
      me._tabModuleSetState(i, true);
    },

    tabShown: function(tab)
    {
      var me = this;
      var f = me.layout[tab];

      if (f.menuName) return;
 
      nav.moduleFromPath(f).then( () =>
      {
	var mod = f.module;
        if (!mod.state) mod.state = {};
        nav.changeURL({ [me.name]: mod.name});
        this._tabModuleSetState(tab,false);
      });
    },
    
    tabHidden: function(tab)
    {
      var me = this;
      var f = me.layout[tab];
      var mod = f.module;
      
      var vars = _.isFunction(mod.stateVars) ? mod.stateVars() : [];
      var diff = {};
      vars.forEach(function(el)
      {
        diff[el] = null;
      });
      nav.changeURL(diff);
    },
    
    _tabModuleSetState(tab,show)
    {
      var me = this;
      var f = me.layout[tab];
      if (!f)
      {
        throw new Error("_tabModuleSetState: no module '"+tab+"' in tabpanel '"+me.name+"'");
      }
      nav.moduleFromPath(f)
      .catch((err) => console.log(err))
      .then( () =>
      {
        var mod = f.module;
        me.state[me.name] = mod.name;

        var cont = me.$("#"+me.name+"-"+tab);
        if (!cont.length) throw new Error("tab: No container found: #"+me.name+"-"+tab);
        nav.promiseCreateModule(mod, cont, me.modParams)
          .done(function()
          {
            mod.setState(me.state);
            me.currentModIndex = tab;
            if (show)
            {
              me.$("a[href='#"+me.name+"-"+tab+"']").tab("show");
            }
          });
       });
    },
    
  }); // object
});
