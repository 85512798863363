<template>
  <SelectAdv :name=name :value=value :search=search :init=init @input="$emit('input', $event)" />
</template>

<script>
import SelectAdv from '_lib/select_adv/SelectAdv.vue'
import darsan from "darsan"
import _ from "underscore"
import $ from "jquery"

const _typeToIcon =
    {
      switch: "sitemap",
      pon: "lightbulb-o",
      pontree: "tree",
      smart: "archive",
      radio: "wifi",
    }

export default {
  name: "DeviceInput",

  components: {SelectAdv},

  props: {
    name: {
      type: String,
    },
    value: {
       type: String,
       default: null,
    },
  },

  methods: {    
/*
    init(opt) {
      return { id: null, name: "" }
    },
*/
    search(query) {
      return darsan.get("", "device", "/srv/search", { nameMask: query, type: ["switch","pon","pontree","smart","radio", "any"] })
        .fail(darsan.err)
        .then(function (data) {

          return $.map(data, el => 
          { 
            return { 
              id: el.device_entity, 
              name: el.name_ru||dev.name,
              type: el.device_entity.replace(/[^a-z]/g,""),
              disabled: el.disabled, 
            }
          });
        })
    },
  }
}
</script>
