<template>
<div class="search-panel-container well well-sm">

<template v-if="ready">

<form class="search-panel-form" @keypress=enter >

  <div v-for="val in controlList" :key=val.name style="margin-bottom: 5px;" class="form-group form-group-sm" :class='{"has-feedback": val.wildcard || val.implicit_wildcard}' >
    <label :for=val.name style="font-size: 12px; margin-bottom: 0px;">
    {{val.name_ru}}
    <span v-if="val.range"> (От, До)</span></label>

    <input v-if="val.type=='string'||val.type=='array'" v-model="model[val.name]" class='form-control' />
    
    <input v-if="val.type=='integer'" type="number" v-model.number="model[val.name]"  class='form-control' :min=val.min :max=val.max />

    <template v-if="val.type=='float'">    
      <input type="number" v-model.number="model[val.name]" step="any" class='form-control' :min=val.min :max=val.max />
    </template>
    
    <RadixDeviceInput v-if="val.type=='radix-device'" v-model="model[val.name]" class="form-control" />
    <RadixTariffInput v-if="val.type=='radix-tariff'" v-model="model[val.name]" class="form-control" />

    <div v-if="val.type=='compare'" class='input-group'>
      <span class="input-group-btn">
        <select class="btn btn-default compare-operator" v-model="model['_'+val.name+'-operator']" style="height: 30px">
          <option value='=' selected="selected">=</option>
          <option value='>'>&gt;</option>
          <option value='<'>&lt;</option>
         </select>
      </span>
      <input v-model="model[val.name]" class='form-control compare-value' />
    </div>

    <select v-if="val.type=='boolean'" v-model="model[val.name]" class='form-control'>
      <option value=""></option>
      <option value='TRUE'>Да</option>
      <option value='FALSE'>Нет</option>
    </select>

    <SimpleSelect2 v-if="val.type=='oneof'" v-model="model[val.name]" :options=val.values :withBlank="true" />

    <DatePicker v-if="val.type=='date'" v-model="model[val.name]" :withTime="!!val.time" :range="!!val.range" />

    <span v-if="val.wildcard" class="text-info form-control-feedback" 
      title="Можно использовать *" aria-hidden="true" style="font-weight: 900; top: 21px;">*</span>

    <span v-if="val.implicit_wildcard" class="text-info form-control-feedback" 
      title="Поиск по части слова" aria-hidden="true" style="font-weight: 900; top: 21px;">*</span>
  </div>

  </form>
                                    
  <div class="btn btn-primary" @click="submitSearch" >
    <Icon name=search />
    <span class="visible-xs-inline visible-lg-inline">&nbsp;Искать</span>
  </div>

  <div class="btn btn-default" title="Сбросить поиск" @click="clearSearch" >
    <Icon name=erase />
  </div>
</template>

<template v-else>
<SkelSearchInput v-for="i in 4" :key=i />
</template>

</div>
</template>

<script>

import darsan from "darsan"
import {parse, SyntaxError} from "common/search/grammar.js"
import RadixDeviceInput from "common/misc/RadixDeviceInput.vue"
import RadixTariffInput from "common/misc/RadixTariffInput.vue"
import DatePicker from "common/misc/DatePicker.vue"
import SkelSearchInput from "common/visual/SkelSearchInput.vue"
import SimpleSelect2 from "common/visual/SimpleSelect2.vue"

export default {

  name: 'SearchPanel',
  
  components: {RadixDeviceInput, RadixTariffInput, DatePicker, SkelSearchInput, SimpleSelect2},

  props: {
    metaApiTopic: String,
    metaApiPath: String, 
    query: String,
  },
  
  async created()
  {
    const controlList = await darsan.get("", this.metaApiTopic, this.metaApiPath)

    const promises = []
    controlList.forEach( (el,i) =>
    {
      if (el.type=="darsan-oneof")
      {
        promises.push(darsan.get("", el.topic, el.path).done(function(l)
        {
          el.values = l.map( el =>
          {
            return {text: el.display_name, value: el.entity}
          })

          el.type = "oneof"
        }))
      }
    })
    
    await Promise.all(promises)
    
    this.controlList = controlList
    this.ready = true

    // Добавляем поля в this.model
    this.controlList.forEach(el => 
    {
      this.$set(this.model, el.name, "")
      if (el.type=="compare")
      {
        this.$set(this.model, "_"+el.name+"-operator", "=")
      }
    })
    
    // Устанавливаем поля ввода по строке поиска
    this.loadValuesFromQuery()
  },
  
  data: function()
  {
    return {
      ready: false,
      controlList: null,
      model: {},
    }
  },
  
  methods:
  {
    makeSearchString()
    {
      const search = [];

      for (const n in this.model)
      {
        if (n.substr(0,1)=="_") continue

        const val = this.model[n];
        if (val != "") 
        {
          search.push(this._operation(n, val));
        }
      }
      
      return search.join(" AND ");
    },
    
    _operation(name, val)
    {
      const meta = this._meta(name);

      switch(meta.type)
      {
        case "string":
          if (meta.wildcard && val.match(/\*/))
          {
            return name+' LIKE "'+val+'"';
          }
          else if (meta.implicit_wildcard && !val.match(/\*/))
          {
            return name+' LIKE "*' + val + '*"';
          }
          else
          {
            return name+'="'+val+'"';
          }
        break;
        
        case "oneof":
        case "radix-device":
        case "array":
          return name+'="'+val+'"';
        break;

        case "integer":
        case "float":
        case "radix-tariff":
          return name+'='+val;
        break;
        
        case "boolean":
          return name+'='+ (val=="TRUE" ? 'TRUE' : 'FALSE');
        break;
        
        case "date":
           if (meta.range)
           {
             const times = val.split(" - ")
             return name + '>"' + this.moment(times[0]).format("YYYY-MM-DD HH:mm:ssZZ") + '" AND ' +
                    name + '<"' + this.moment(times[1]).format("YYYY-MM-DD HH:mm:ssZZ") + '"'
           }
           else
           {
             return name + "=" + '"' + this.moment(val).format("YYYY-MM-DD HH:mm:ssZZ") + '"'
           }
        break;

        case "compare":
          return name + this.model["_"+name+"-operator"] + '"' + val + '"'
        break;
      }
    },
    
    _meta: function(name)
    {
      return this.controlList.find(el => el.name===name);
    },
    
    submitSearch()
    {
      this.$emit("new-search", this.makeSearchString());
    },
    
    keyup(ev)
    {
      if (ev.key == "Enter")
      {
        this.submitSearch();
      }
    },
    
    clearSearch() {
      this.controlList.forEach(el => {
        if (el.type == 'compare') {
          this.$set(this.model, '_' + el.name + '-operator', '=')
        } else {
          this.$set(this.model, el.name, '')
        }
      });
    
      this.$emit('new-search', null);
    },
    
    parse: function(q)
    {
      let tree;
      
      try {
        tree = parse(q);
      }
      catch(e)
      {
        return e;
      }
      
      return tree;
    },
    
    parseSearchString: function(q)
    {
      if (!q)
      {
        return [];
      }
    
      const tree = this.parse(q);
      
      if (tree instanceof SyntaxError)
      {
        console.log("parseSearchString: ",q,tree);
        return [];
      }

      return this._extractValues(tree); 
    },
    
    _extractValues: function(tree)
    {
      if (tree.list)
      {
        let list = [];
        for (const el of tree.list)
        {
          list = list.concat(this._extractValues(el));
        }

        return list;
      }
      else
      {
        return [ {name: tree.l, val: tree.r, op: tree.op} ];
      }
    },
    
    setValuesFromTree(parsed)
    {
      const ranges = {}
    
      parsed.forEach(el =>
      {
        const meta = this._meta(el.name)
        let value = el.val
        
        if (meta.type=="date")
        {
          const d = this.moment(el.val)
          value = d.format(meta.withTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD")

          if (meta.range)
          {
            if (!ranges[el.name]) ranges[el.name] = {}

            if (el.op==">")
            {
              ranges[el.name].from = value
            }
            else
            {
              ranges[el.name].to = value
            }
          }
        }

        if (!meta.range) this.model[el.name] = value
      })
      
      for (const name in ranges)
      {
        this.model[name] = ranges[name].from + " - " + ranges[name].to
      }
    },
    
    loadValuesFromQuery()
    {
      if (!this.ready) return
      this.setValuesFromTree( this.parseSearchString(this.query) )
    },
    
    enter(ev)
    {
      if (ev.key=="Enter")
      {
        this.submitSearch()
      }
    },

  },
  
  computed: {
  },
  
  watch: {
    query() { this.loadValuesFromQuery() },
  },
};
                                                              
</script>

<style scoped>
  .input-range-start.form-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-range-end.form-control {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  @media screen  and (max-device-width: 480px) and (orientation: portrait) {
    .search-panel-form {
      overflow-x: hidden;
      margin-bottom: 10px;
    }

    .search-panel-container {
    }
  }

  @media screen  and (min-device-width: 1024px) and (orientation: landscape)  {
    .search-panel-form {
      max-height: calc(100vh - 140px);
      overflow-x: hidden;
      margin-bottom: 10px;
    }

    .search-panel-container {
      max-height: 100vh;
    }
  }
</style>
