window.CKEDITOR.editorConfig = function (config) {
  config.filebrowserBrowseUrl = '/filemanager?root=news';
  config.plugins = "a11yhelp,ajax,autocomplete,basicstyles,blockquote,button,clipboard,contextmenu,dialog,dialogui,elementspath,emoji,enterkey,entities,fakeobjects,filebrowser,filetools,floatingspace,floatpanel,format,horizontalrule,htmlwriter,image,indent,indentlist,lineutils,link,list,listblock,magicline,maximize,menu,menubutton,notification,notificationaggregator,panel,panelbutton,pastefromword,pastetext,popup,removeformat,resize,richcombo,showborders,sourcearea,specialchar,stylescombo,tab,table,tableselection,tabletools,textmatch,textwatcher,toolbar,undo,uploadimage,uploadwidget,widget,widgetselection,wsc,wysiwygarea,xml";
//  config.protectedSource.push( /<%[\s\S]*?%>/g );
  config.allowedContent = true;
  config.basicEntities = false;
  config.entities = false;
  config.language = "ru";
  config.emoji_emojiListUrl = "/conf/emoji2.json";
}
