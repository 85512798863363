<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>
    <div :class="'col-sm-'+size">
      <input class="form-control" autocomplete="off" :name=name :id=uniq :value=localValue :maxlength=maxlength :disabled=disabled
      @change=newValue @focus=savePrevious />

      <Icon v-show="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />

      <span class="help-block">{{error}}</span>
    </div>

    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>

  </div>
</template>

<script>

import CooperMixin from "common/visual/cooper/CooperMixin.vue"

export default {
  name: "Input",
  
  mixins: [CooperMixin],
  
  data() 
  {
    return {
      element: "input",
    }
  },
  
  props: {
    maxlength: {
      type: Number,
      default: null,
    },
  },

}
</script>

