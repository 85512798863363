define(['jquery', 'underscore','_lib/select_adv/select_adv.css'], function ( $, _ ){

	var mainTpl = `<div class="form-input select_adv form-control" style="background-color:white;">/div>`;
	var dropTpl = `<div class="select_adv_dropdown">
			<div style="margin:5px;"><input class="search form-input form-control" placeholder="Поиск..." autofocus><i class="fa fa-search icon-search" aria-hidden="true"></i></div>
			<div><ul class="values"></ul></div>
			</div>`;

	var singleTpl = _.template(`<div class="select_adv_single"><div class="select_adv_data_container"><div class="select_adv_left_addon"><%=leftAddon%></div><span class="name"><%=data%></span></div><span class="arrow">&#9660;</span></div>`);
	var multiTpl = _.template(`<%_.map(data, function(v,k){%>
				    <div class="select-value">
					<span><%=v%></span>
					&nbsp;
					<span><a class="button remove" key="<%=k%>" href="#"><i class="fa fa-times"></i></a></span>
		    		    </div><%});%>`);

	return function( el, options ){
		if( !el || !el.get(0) ) return console.error('select_adv: invalid el');
		var data = [], dropdown = 0, tagName = el.get(0).tagName;

		options = _.defaults(options||{}, {});
		if( el.attr('multiple') || options.multiple ){ 

		    options.multiple = true;
		    el.attr('multiple', true)

		}

		var els = $(mainTpl), eld = $(dropTpl);
		if( options.width ) els.css({ width: options.width });
		if( options.display ) els.css({ display: options.display });
		if( options.disabled ) els.css({ opacity: 0.4 });
		if (options.title) els.attr('title', options.title);

		/*Генератор шаблона выпадающего списка*/
		function values( data ){

			var data2 = ( options.sort ) ? _.sortBy(data, function(v){ return v.name }) : data;
			if( options.limit ) data2 = data2.slice(0, options.limit);

			/*Выбранные занчения*/
			var used = [];
			if( options.multiple ){
			    used = ( tagName == 'INPUT' ) ? String(el.val()).split(',') : el.val()||[];
			}

			function option_tpl( v ){

			    /*Пропускаем уже выбранные значения*/
			    if(_.contains( used, v.id )) return;

			    var $el = $(`<li class="select_adv_value"><div class="value-option"><a href="#" class="value" key="`+v.id+`"></a></div></li>`);

			    /*Доп поиск*/
			    if( options.onadvsearch ) $el.find('.value-option').append(`&nbsp<a key="`+v.id+`" class="advsearch" href="#"><i class="fa fa-search"></i>&nbsp;</a>`);
			    $el.find('a').append((_.isFunction( options.template )) ? options.template( v ) : v.name);

			    return $el;
			}

			function group_tpl( v ){
			    var $el = $(`<li class="select_adv_group"><div class="name">`+v.name+`</div><ul class="group_values"></ul></li>`);
			    return $el;
			}

			var array = [], limit = options.limit||100;
			_.some(data2, function(v,k){

			    if( v.children ){
				var $el = group_tpl( v );

				var array_c = [];
				_.some( v.children, function( v_c, k_c ){

				    array_c.push( option_tpl( v_c ) );

				    limit--;
				    if( limit <= 0 ) return 1;
				});

				$el.find('.group_values').html( array_c );
				array.push( $el );

				if( limit <= 0 ) return 1;

			    }else{

				array.push( option_tpl( v ) );
				limit--;
				if( limit <= 0 ) return 1;
			    }
			});

			/*Не значений*/
			if( _.isEmpty(array)) array.push(`<li class="select_adv_value">Нет элементов для выбора</li>`);
			return array;

		};

		/*Функция поиска по умочланию*/
		function search( val ) {
		    var r = new RegExp(val, 'i');
    		    return _.filter(data, function(v){ 
			return String(v.name).match(r); 
		    });
		};

		/*Поиск по пользовательсекому тексту*/
		function find_values(){

			var val = eld.find('.search').val();
			eld.find('.values').empty();

			/*Внутренняя или внешняя функции поиска*/
			if( _.isFunction( options.onsearch )){
			    if( !val ) return;

			    data = [];
			    options.onsearch( val, function(out){
				data = out;
				eld.find('.values').html(values( out ));
			    });

			}else{
			    eld.find('.values').html(values(search( val )));
			}
		}

		/*Извлеченеи из коллекции по id*/
		function get_option( id ){

		    var v;
		    _.some(data, function(v2){
			if( v2.id == id ){ 
			    v = v2;
			    return 1;
			}
		    });

		    return v;
		};

		/*Установка значения*/
		function set_value( out ){

		    if( tagName == 'SELECT' ){
		        el.html( _.map(out, function(v){ return '<option value="'+v+'"></option>'; }) );
		    }
		    return el.val( out );

		}


		/*=============================================================================================================================*/

		/*Прячем основной и вcтавляем свой элемент*/
		el.hide().after( els );

		/*Событие изменения (Рендерит основное меню)*/
		el.on('change init:change', function(e){
			if( options.multiple ){

			    /*Выбранные значения*/
			    var val = el.val();
			    var opt = val||[];

			    if( tagName == 'INPUT' && val != '' ){
				opt = String(val).split(',');
			    }

			    if( _.isFunction( options.oninit )){

				var array = {};
				_.map(opt, function( id ){
				    array[id] = get_option( id )||{ id: id, name: '?' };
				});

				options.oninit( array, function( array ){
				    array = _.map(array, function(v ){
					return (_.isFunction( options.template )) ? options.template( v ) : v.name;
				    });
				    els.addClass('select_adv_multi').html( multiTpl({ data: array }) );
				});

			    }else{

				var array = {};
				_.map(opt, function( id ){
				    var v = get_option( id )||{ id: id, name: '?' };
				    array[id] = (_.isFunction( options.template )) ? options.template( v ) : v.name;
				});
				els.addClass('select_adv_multi').html( multiTpl({ data: array }) );
			    }

			}else{
			    var id = el.val()||el.attr('value');
			    var v = get_option( id )||{ id: id, name: '?' };

			    if( _.isFunction( options.oninit )) {
				options.oninit( v, function( v ) {
					var name = (_.isFunction( options.template )) ? options.template( v ) : v.name;
					els.html( singleTpl({ data: name, leftAddon: options.leftAddonTemplate }) );
				});

			    } else {
				var name = (_.isFunction( options.template )) ? options.template( v ) : v.name;
				els.html( singleTpl({ data: name, leftAddon: options.leftAddonTemplate }) );
			    }
			}
		});

		/*=================================================Наполнение коллекции==================================================*/

		/*Источник данных массив*/
		if( _.has( options, 'data') ){

		    data = JSON.parse(JSON.stringify(options.data));

		/*Источник данных селектор*/
		}else{

		    if( tagName == 'SELECT' ){
			el.find('option').each(function(k,v){
			    var o = $(v);
			    data.push({ id: o.attr('value'), name: o.text() });
			});
		    }

		}

		/*=====================================================Инициалищация=====================================================*/

		var sel = [];
		_.map(data, function(v){ 
		    if( v.selected ) sel.push( v.id );
		});

		/*Множественный выбор*/
		if( options.multiple ){
		    if(!_.isEmpty( sel )) set_value( sel );

		/*Одиночный выбор*/
		}else{
		    if(!_.isEmpty( sel )) set_value([ _.first(sel) ]);
		}

		el.trigger('init:change');

		/*=====================================================Поиск=====================================================*/

		var t;
		eld.find('.search').on('click', function(e){

		    e.stopPropagation();
		    e.preventDefault();

		}).on('keypress', function(e){

			if(e.keyCode == 13){
			    e.preventDefault();
			    e.stopPropagation();
			    find_values();
			}

		}).on('input', function(e){
		    e.preventDefault();
		    e.stopPropagation();

		    clearTimeout( t );
		    t = setTimeout( find_values, 500 );
		});

		/*=====================================================Выпадающее меню=====================================================*/
		/*Функции с событиями*/
		/*Закрытие меню*/
		function close_dropdown(){

		    /*Отображение*/
		    eld.detach();

		    /*Вызываем событие закрытия и отключаем сопустсвуищие события*/
		    $(document).trigger('close_dropdown').off('click open_dropdown', callback_click);
		    $(window).off('resize scroll', callback_resize_scroll);

		    /*Флаг закрытого меню*/
		    dropdown = 0;
	
		}

		function open_dropdown(){

		    /*Результаты*/
		    eld.find('.search').val('');
		    eld.find('.values').html( values( data ) );

		    /*Воод данных в поле ввода*/
		    if( options.multiple ){

		    }

		    /*Отображение*/
		    els.after( eld );

		    /*Позиционирование*/
		    callback_resize_scroll();

		    /*Вызываем событие открытия и подключаем сопустсвуищие события*/
		    $(document).trigger('open_dropdown').on('click open_dropdown', callback_click);
		    $(window).on('resize scroll', callback_resize_scroll);

		    /*Установка фокуса*/
		    eld.find('.search').focus();

		    /*Флаг открытого меню*/
		    dropdown = 1;
		}

		/*Ресайз*/
		function callback_resize_scroll(e){

		    var css = els.position();
		    css.width = els.outerWidth();

		    //Определяем в какую сторону показывать меню
		    var pt = els.position().top, pb = window.innerHeight - (pt + els.outerHeight());
		    if( pt > pb ){

			eld.removeClass('bottom').addClass('top');
			eld.find('.values').css({ maxHeight: css.top - 50 });
			css.top -= eld.outerHeight() - 1;

		    }else{

			eld.removeClass('top').addClass('bottom');
			eld.find('.values').css({ maxHeight: "calc(50vh - 50px)" });
			css.top += els.outerHeight() - 1;
		    }
		    eld.css( css );
		}

		/*Закрытие меню если клик за пределами элемента*/
		function callback_click(e){
		    if ( !eld.is(e.target) && eld.has(e.target).length === 0 && !els.is(e.target) && els.has(e.target).length === 0 ) close_dropdown();
		}

		/*Выпадающее меню (Открытие/Закрытие)*/
		els.on('click', function(e){
		    e.preventDefault();
		    e.stopPropagation();

		    if( el.is(':disabled')) return;

		    if( dropdown ){ 
			close_dropdown();
		    }else{
			open_dropdown();
		    }

		});

		/*=====================================================Выбор значения в меню=====================================================*/

		eld.on('click', '.value', function(e){
			e.preventDefault();
			e.stopPropagation();

			var elt = $(e.currentTarget);
			if( elt.hasClass('disabled') ) return;

			var id = elt.attr('key');
			var option = get_option( id )||{ id: id, name: elt.text() };

			/*Множественный выбор*/
			if( options.multiple ){

			    var opt = [];
			    if( tagName == 'SELECT' ){
				opt = el.val()||[];
			    }else{
				if( el.val() != '' ) opt = String(el.val()).split(',');
			    }

			    /*Блокировка выбора одинаковых значений*/
			    if(_.contains( opt, option.id )) return;

			    /*Внутренняя или внешняя функция выбора*/
			    if( _.isFunction( options.onselect )){

				    options.onselect( option, function( option ){

					//Если нету такого параметра, добавляем
					if( !_.findWhere(data, { id: option.id }) ) data.push(option);

					opt.push( option.id );
					set_value( opt ).trigger('change');

					find_values();
					callback_resize_scroll();

				    });

			    }else{

				opt.push( option.id );
				set_value( opt ).trigger('change');

				find_values();
				callback_resize_scroll();
			    }

			/*Одиночный выбор*/
			}else{

			    /*Внутренняя или внешняя функция выбора*/
			    if( _.isFunction( options.onselect )){

				options.onselect( option, function( option ){

				    //Если нету такого параметра, добавляем
				    if( !_.findWhere(data, { id: option.id }) ) data.push(option);

				    set_value([ option.id ]).trigger('change');
				    close_dropdown();

				});

			    }else{

				set_value([ option.id ]).trigger('change');
			        close_dropdown();

			    }
			}
		});

		/*==========================================================================================================*/

		/*Удаление значения*/
		if( options.multiple ){
		    els.on('click', '.remove', function(e){

			e.preventDefault();
			e.stopPropagation();

			if( el.is(':disabled')) return;
			var id = $(e.currentTarget).attr('key');

			var opt = [];
			if( tagName == 'SELECT' ){
			    opt = el.val()||[];
			}else{
			    if( el.val() != '' ) opt = String(el.val()).split(',');
			}

			opt.splice( opt.indexOf( id ), 1 );

			//Устанавливаем значение
			set_value( opt ).trigger('change');

			/*Если dropdown открыт показываем значение и позиционируем*/
			if( dropdown ){ 

			    find_values();
			    callback_resize_scroll();

			}
		    })
		}

		/*Доп поиск*/
		if( _.isFunction( options.onadvsearch )){
		    eld.on('click', '.advsearch', function(e){
			e.preventDefault();
			e.stopPropagation();

			var id = $(e.currentTarget).attr('key');
			var option = get_option( id )||{ id: id, name: $(e.currentTarget).text() };

			data = [];
			options.onadvsearch( option, function( out ){
			    data = out;
			    eld.find('.search').val( option.name ).focus();
			    eld.find('.values').html( values( out ) );
			});
		    });
		}

	    return {
		setValues: function(options){
		    data = JSON.parse(JSON.stringify(options));
		}
	    }
	}
});