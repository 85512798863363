<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>
    <div :class="'col-sm-'+size">
      <input type="number" class="form-control" :name=name :id=uniq :value=localValue min=0 max=999999 step=1
      @change=newValue @blur=newValue @focus=savePrevious />

      <Icon v-if="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />

      <span class="help-block">{{error}}</span>
    </div>

    <Icon v-if="info" name="fa-info-circle" :title=info />
    {{$store.state.userSettings.currency}}
    <slot></slot>

  </div>
</template>

<script>

import CooperMixin from "common/visual/cooper/CooperMixin.vue"
import {fromMainCurrency, toMainCurrency} from "common"

export default {
  name: "Currency",
  
  mixins: [CooperMixin],
  
  data() 
  {
    return {
      element: "input",
    }
  },
  
  methods: {
    decorateValue(val)
    {
      return fromMainCurrency(val)
    },
    undecorateValue(val)
    {
      return toMainCurrency(val)
    }
  },
  
}
</script>

