<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize">{{label}}</label>

    <div :class="'col-sm-'+size">
      <input type=date class="form-control" :name=name :id=uniq :value=localValue @change='$emit("input", $event.target.value)' />
      <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>

import _ from "underscore"

export default {
  name: "Date",
  
  data()
  {
    return { 
       uniq: null,
    }
  },
  
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    size: {
      type: Number,
      default: 6,
    },
    value: {
       type: String,
    },
    labelSize: {
      type: Number,
      default: 3,
    },
    error: {
      type: String,
    }
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("date")
  },
}
</script>
