<template>
<div>
  <h4 class="cursor-pointer collapsed" data-toggle="collapse" :data-target="'#'+uniq"
      aria-expanded="false" aria-controls="collapsedAdditionalTags">
    {{title}}
    <Icon name="chevron-down" class="closed" />
    <Icon name="chevron-up" class="opened" />
  </h4>
  <div class="collapse" :id=uniq>
    <slot></slot>
  </div>
</div>                                                                        
</template>

<script>
import _ from "underscore"

export default {
  name: "Flipper",
  
  props: {
    title: String,
  },
  
  data()
  {
    return {
      uniq: null,
    }
  },
  
  mounted()
  {
    this.uniq = _.uniqueId("flipper")
  },
  
}
</script>

<style scoped>
h4:not(.collapsed) > .closed
{
  display: none;
}

h4.collapsed > .opened
{
  display: none;
}
</style>
