<template>
  <div class="form-group has-feedback" :class="{'has-error': error}" style="margin-bottom: 0px">
    <label :for=uniq class="control-label" :class="'col-sm-'+labelSize" >{{label}}</label>
      <div :class="'col-sm-'+size">
          <select class="form-control" :multiple=multiple :name=name :id=uniq >
            <option v-for="o in options" :key=o.value :value=o.value >{{o.text}}</option>
          </select>
    <Icon v-show="has_undo" name=fa-reply class="form-control-feedback opacity-half-full"
      style="cursor: pointer; pointer-events: auto;" @click.native=undo />      

     <span class="help-block">{{error}}</span>
    </div>
    <Icon v-if="info" name="fa-info-circle" :title=info />
    <slot></slot>
  </div>
</template>

<script>

import CooperMixin from "common/visual/cooper/CooperMixin.vue"
import $ from "jquery"

export default {
  name: "Select2",
 
  mixins: [CooperMixin],
  
  data() 
  {
    return {
      element: "select",
    }
  },
  
  mounted()
  {
    const el = $(this.$el.querySelector("select"))
    el.select2()
    
    el.on("change", ev => this.newValue(ev))
    el.on("select2-open", () => this.savePrevious())
    el.on("select2-removing", () => this.savePrevious())
    el.select2("val", this.value)
  },
    
  props: {
    options: {
      type: Array,
    },
    multiple: Boolean,
  },
  
  watch: {
    value(val) { $(this.$el.querySelector("select")).select2("val", this.value) }
  },
  
  
}

</script>
